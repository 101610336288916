import React from "react";
import Link from "next/link";

import { StyledWrapper, StyledImage, StyledContent } from "./styled";

import { PortfolioLinkProps } from "./types";

const PortfolioLink: React.FC<PortfolioLinkProps> = ({
  href,
  imageSrc,
  title,
}) => (
  <Link href={href}>
    <StyledWrapper>
      <StyledImage src={imageSrc} width={800} height={579} priority />
      <StyledContent>
        <h3 className="text-white text-lg">Project Showcase</h3>
        <h4 className="text-white text-2xl font-semiBold mt-1 ">{title}</h4>
      </StyledContent>
    </StyledWrapper>
  </Link>
);

export default PortfolioLink;
