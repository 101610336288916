import styled from "styled-components";
import tw from "twin.macro";
import Image from "next/image";

export const StyledContent = styled.div`
  ${tw`absolute w-full  bottom-0 px-5 pb-8 transition duration-100 ease-in-out sm:py-10 sm:px-10`}
`;

export const StyledWrapper = styled.a`
  ${tw`block relative overflow-hidden sm:rounded`}
  > div {
    ${tw` sm:rounded`}
  }
  &:hover {
    img {
      transform: scale(1.025);
    }
    ${StyledContent} {
      transform: translateX(5px);
    }
  }
`;

export const StyledImage = styled(Image)`
  ${tw`transition duration-100 ease-in-out sm:rounded`}
`;
