import React from "react";
import Link from "next/link";

import { Post } from "@/types";

const PostLink: React.FC<Post> = ({ link, module: { meta } }) => (
  <a href={`https://lukebrown.dev/writing${link}`} target="_blank" rel="norefferer">
    <a>
      <h4 className="font-bold text-lg">{meta.title}</h4>
      <p className="leading-extra-relaxed mt-4">{meta.subtitle}</p>
      <p className="text-pink mt-4 underline font-semiBold hover:no-underline">
        Read more
      </p>
    </a>
  </a>
);

export default PostLink;
