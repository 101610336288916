import React from "react";

const Quote: React.FC = ({ children }) => (
  <div className="mt-6 pl-4 md:pl-6">
    <svg
      width="27"
      height="19"
      viewBox="0 0 27 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M8.13018 0.595705C8.01616 0.60062 7.90509 0.631089 7.80384 0.683186C7.80384 0.683186 6.33733 1.4312 4.79211 2.92424C3.18503 4.4753 2.04282 6.72609 1.43639 8.98898C0.822068 11.2812 0.848615 13.361 1.59366 15.1471C2.33674 16.9311 3.73937 18.1636 5.59228 18.6601C7.12664 19.0709 8.67573 18.8409 10.0617 18.0153C11.4466 17.1896 12.3981 15.9344 12.814 14.3815C13.2435 12.7803 13.0499 11.2135 12.2213 9.87182C12.2174 9.86788 12.2144 9.86395 12.2105 9.86002C11.373 8.54783 10.052 7.65239 8.39077 7.2071C8.07231 7.12159 7.65849 7.15501 7.29481 7.12651C7.48254 6.47778 7.6693 5.89393 8.31999 5.10661C9.08766 4.17971 11.0034 2.62178 11.0034 2.62178C11.2363 2.43601 11.3444 2.13524 11.2835 1.8433C11.2216 1.55236 11.0014 1.32039 10.7134 1.24568L8.36815 0.62446C8.2905 0.602836 8.2099 0.593007 8.13029 0.595956L8.13018 0.595705ZM21.2601 0.595705H21.2592C21.1461 0.60062 21.035 0.631089 20.9338 0.683186C20.9338 0.683186 19.4614 1.4312 17.9163 2.92424C16.3092 4.4753 15.173 6.72609 14.5666 8.98898C13.9523 11.2812 13.9788 13.361 14.7229 15.1471C15.4669 16.9311 16.8647 18.1636 18.7175 18.6601C20.2518 19.0709 21.8009 18.8409 23.1869 18.0153C24.5718 17.1896 25.5282 15.9344 25.944 14.3815C26.3735 12.7803 26.175 11.2135 25.3464 9.87182C25.3444 9.86788 25.3424 9.86395 25.3405 9.86002C24.503 8.54783 23.182 7.65239 21.5208 7.2071C21.2013 7.12159 20.7826 7.15501 20.4179 7.12651C20.6066 6.4768 20.7993 5.89491 21.452 5.10661C22.2186 4.17971 24.1275 2.62178 24.1275 2.62178C24.3605 2.43601 24.4686 2.13524 24.4067 1.8433C24.3457 1.55236 24.1246 1.32039 23.8366 1.24568L21.4913 0.62446C21.4166 0.603819 21.338 0.59399 21.2594 0.595956L21.2601 0.595705Z"
          fill="#CC3363"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="26"
            height="19"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>

    <blockquote className="leading-extra-relaxed mt-4 font-bold italic">
      {children}
    </blockquote>
  </div>
);

export default Quote;
