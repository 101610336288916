import React from "react";

import { ImageProps } from "./types";

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  caption,
  withBrowser = false,
}) => (
  <div
    className={
      withBrowser
        ? `border-grey border-l-2 border-b-2 border-r-2 rounded-md -mx-5 md:-mx-6 my-8`
        : "my-8"
    }
  >
    {withBrowser && (
      <img src="/images/browser-top.svg" alt="" className="w-full" />
    )}

    <img src={src} alt={alt} className="w-full" />

    {caption && (
      <p className="italic text-sm font-semiBold -mt-6 pt-1 border-t-2  border-pink">
        {caption}
      </p>
    )}
  </div>
);

export default Image;
