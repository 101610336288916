import React from "react";
import Lottie from "react-lottie";

import { Button } from "@/components";

import { IconTextProps } from "./types";

const IconText: React.FC<IconTextProps> = ({
  children,
  title,
  icon,
  button,
}) => (
  <div>
    <div className="flex items-center">
      <div className="w-10">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: icon,
          }}
        />
      </div>
      <h3 className="ml-6 text-lg font-bold">{title}</h3>
    </div>
    <p className="mt-4 leading-extra-relaxed">{children}</p>
    {button && (
      <div className="mt-8">
        <Button {...button} />
      </div>
    )}
  </div>
);

export default IconText;
