module.exports = {
  theme: {
    fontWeight: {
      normal: 400,
      semiBold: 500,
      bold: 700,
      extraBold: 900,
    },
    fontFamily: {
      sans: ["AvertaPE", "Helvetica", "Arial"],
      serif: ["AvertaPE", "Helvetica", "Arial"],
      mono: ["Source Code Pro", "mono space"],
    },
    screens: {
      xs: "375px",
      sm: "640px",
      md: "768px",
      lg: "960px",
      xl: "1020px",
    },

    extend: {
      textColor: {
        primary: "#262729",
      },
      colors: {
        black: "#262729",
        primary: "#CC3363",
        secondary: "#AE1446",
        grey: "#F7F7F7",
        pink: "#CC3363",
      },
      fontSize: {
        "4.5xl": "2.6rem",
      },
      lineHeight: {
        "extra-relaxed": "1.8",
      },
    },
  },
  variants: {},
  plugins: [],
  purge: false,
};
