import React, { useEffect } from "react";
import Image, { ImageProps } from "next/image";
import Head from "next/head";

import {
  Banner,
  Container,
  ContainerBreak,
  NavigationSpacer,
  Button,
  AnimateIn,
} from "@/components";
import { BannerProps } from "@/components/Banner/types";
import { ButtonProps } from "@/components/Button/types";
import { useMenu, MenuTheme } from "@/context";

import { StyledBanner } from "./styled";

// TODO move these
type ProjectProps = {
  banner: BannerProps;
  mobileBannerSrc: string;
  desktopBannerSrc: string;
  headline?: string;
  details?: { title: string; text: string }[];
  button?: ButtonProps;
  image?: ImageProps;
};

const Project: React.FC<ProjectProps> = ({
  banner,
  mobileBannerSrc,
  desktopBannerSrc,
  headline,
  details,
  button,
  image,
  children,
}) => {
  const { setTheme } = useMenu();
  useEffect(() => {
    setTheme(MenuTheme.Image);
  }, []);

  return (
    <>
      <Head>
        <title>{banner.title} | Luke Brown</title>
        <meta name="description" content={headline} />
      </Head>
      <StyledBanner
        mobileBannerSrc={mobileBannerSrc}
        desktopBannerSrc={desktopBannerSrc}
      >
        <NavigationSpacer />
        <Banner {...banner} isSnug />
      </StyledBanner>
      <AnimateIn>
        <Container>
          <article className="py-10 lg:pt-12 lg:pb-16">
            {headline && (
              <h2 className="text-lg font-bold leading-relaxed">{headline}</h2>
            )}
            {details && (
              <div className="mt-6">
                {details.map((detail, idx) => (
                  <div className={idx === 0 ? "flex" : "flex mt-2"} key={idx}>
                    <p className="w-32 flex-shrink-0 flex-grow-0 opacity-50 font-semiBold">
                      {detail.title}
                    </p>
                    <p>{detail.text}</p>
                  </div>
                ))}
              </div>
            )}
            <div className="mt-6 leading-extra-relaxed">{children}</div>

            {button && (
              <div className="mt-10">
                <Button {...button} newWindow />
              </div>
            )}

            {image && (
              <div className="mt-10">
                <ContainerBreak>
                  <Image {...image} />
                </ContainerBreak>
              </div>
            )}
          </article>
        </Container>
      </AnimateIn>
    </>
  );
};

export default Project;
