import React from "react";

import { Button } from "@/components";
import { ButtonProps } from "@/components/Button/types";

type TextProps = {
  title?: React.ReactNode;
  button?: ButtonProps;
  isDark?: boolean;
};

const Text: React.FC<TextProps> = ({
  title,
  button,
  children,
  isDark = false,
}) => (
  <article className={isDark ? "text-white" : ""}>
    {title && <span className="text-xl leading-relaxed font-bold">{title}</span>}

    {children && (
      <p className={`${title ? "mt-8 md:mt-8" : "mt-0"} leading-extra-relaxed`}>
        {children}
      </p>
    )}

    {button && (
      <div className="mt-10">
        <Button {...button} />
      </div>
    )}
  </article>
);

export default Text;
