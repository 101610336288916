import React from "react";

import { TextFieldProps } from "./types";

const TextField: React.FC<TextFieldProps> = ({
  name,
  label,
  multiline = false,
  type = "text",
  required = true,
  onChange = () => {},
  value,
  ...rest
}) => {
  const inputClassName =
    "mt-2 rounded block border-black border-2 w-full px-3 py-2 focus:border-pink outline-none";

  return (
    <div {...rest}>
      {label && (
        <label htmlFor={name} className="font-bold">
          {label}
        </label>
      )}
      {multiline ? (
        <textarea
          id={name}
          name={name}
          onChange={onChange}
          required={required}
          className={`${inputClassName} h-32`}
          value={value}
        />
      ) : (
        <input
          id={name}
          name={name}
          onChange={onChange}
          type={type}
          value={value}
          required={required}
          className={inputClassName}
        />
      )}
    </div>
  );
};
export default TextField;
