import styled from "styled-components";
import tw from "twin.macro";

export const StyledH2 = styled.h2`
  ${tw`mt-8 text-xl font-bold leading-snug`}
`;

export const StyledH3 = styled.h2`
  ${tw`mt-8 text-lg font-bold leading-relaxed`}
`;

export const StyledH4 = styled.h2`
  ${tw`mt-8 text-lg font-bold`}
`;

export const StyledP = styled.p`
  &:not(:first-child) {
    ${tw`mt-6`}
  }
`;

export const StyledA = styled.a`
  ${tw`text-pink underline hover:no-underline`}
`;

export const StyledQuote = styled.blockquote`
  ${tw`font-bold italic mt-4 pl-6`}
  &::before {
    ${tw`text-pink text-3xl -mb-4 -ml-2 block`}
    content: open-quote;
  }
`;

export const StyledUl = styled.ul`
  ${tw`mt-4`}
  li {
    ${tw`relative pl-5`}
    &:not(:first-child) {
      ${tw`mt-4`}
    }

    &::before {
      ${tw`w-2 h-2 absolute bg-pink`}
      top: 0.65rem;
      left: 0;
      border-radius: 50%;
      content: "";
    }
  }
`;

export const StyledOl = styled.ol`
  ${tw`mt-4`}
  li {
    ${tw`relative pl-5`}
    counter-increment: step-counter;
    &:not(:first-child) {
      ${tw`mt-4`}
    }

    &::before {
      ${tw`absolute text-sm font-bold text-pink`}
      top: 0.126rem;
      left: 0;
      border-radius: 50%;
      content: counter(step-counter);
    }
  }
`;

export const StyledWrapper = styled.article`
  ${tw`mt-8 mb-12 leading-extra-relaxed md:mt-10 md:mb-20`}
  max-width: 650px;

  img {
    ${tw`block w-full my-6`}
  }

  p code {
    ${tw`bg-grey px-1`}
    border: 1px solid #dcdcdc;
  }
`;
