import styled from "styled-components";
import tw from "twin.macro";

export const StyledFooter = styled.footer`
  ${tw`bg-grey`}
  border-top: 1px solid #E9E9E9;
`;

export const StyledNav = styled.nav`
  ul {
    ${tw`flex flex-wrap mt-4`}
    li {
      ${tw`pt-3`}
      &:not(:last-child) {
        ${tw`mr-4 sm:mr-8`}
      }

      a {
        ${tw`underline hover:no-underline hover:text-primary`}
      }
    }
  }
`;
