import React from "react";
import Link from "next/link";
import { motion } from "framer-motion";

import { useMenu } from "@/context";
import { Logo } from "@/components";

import { StyledWrapper, StyledLogo, StyledNav } from "./styled";
import { MobileMenu } from "./components";

const Navigation = () => {
  const { theme, isOpen, openMenu, closeMenu } = useMenu();

  return (
    <div className="relative">
      <StyledWrapper isMenuOpen={isOpen} menuTheme={theme}>
        <motion.div
          className="flex justify-between flex-1 max-w-4xl"
          initial="initial"
          animate="enter"
          exit="exit"
          transition={{ duration: 0.2 }}
          variants={{
            initial: { y: 20, opacity: 0 },
            enter: { y: 0, opacity: 1 },
            exit: { y: 20, opacity: 0 },
          }}
        >
          <StyledLogo>
            <Logo />
          </StyledLogo>
          <StyledNav isMenuOpen={isOpen} menuTheme={theme}>
            <li className="hidden sm:block">
              <Link href="/services">Services</Link>
            </li>
            <li className="hidden sm:block">
              <Link href="/work">Projects</Link>
            </li>
            <li className="hidden sm:block">
              <Link href="/contact">Contact</Link>
            </li>
            <li className="hidden sm:block">
              <a href="https://lukebrown.dev" target="_blank" rel="norefferer">Writing</a>
            </li>
            <li className="block sm:hidden">
              <button
                aria-label="Open Mobile Menu"
                onClick={isOpen ? closeMenu : openMenu}
              >
                {isOpen ? "Close Menu" : "Open Menu"}
              </button>
            </li>
          </StyledNav>
        </motion.div>
        <MobileMenu />
      </StyledWrapper>
    </div>
  );
};

export default Navigation;
