export const EMAIL_ADDRESS = "hello@lukebrown.io";
export const TWITTER_LINK = "https://twitter.com/Lukejohnbrown";

export const PROJECTS = [
  {
    id: "DCFF2D84-3471-417A-8C78-998A17902060",
    title: "Sweet Spot Studio",
    subtitle: "Cardiff Based Recording Studio",
    href: "/work/sweet-spot-studio",
    externalHref: "https://sweetspotstudio.co.uk",
    imageSrc: "/images/portfolio/sweet-spot-studio-link.jpg",
    summary:
      "Sweet Spot Studio is an up and coming recording studio based in Llandaff, Cardiff. Andie and Steffan needed a modern website that would showcase their services aimed at local talent.",
  },
  {
    id: "013E8449-5D1B-41BD-8CCA-14C085038C7E",
    title: "Pukka Productions",
    subtitle: "Theatre School and Drama Group",
    href: "/work/pukka-productions",
    externalHref: "https://pukkaproductions.co.uk",
    imageSrc: "/images/portfolio/pukka-productions-link.jpg",
    summary:
      "Having grown up in the area, it was a joy to work with this Barry based theatre school and drama group. When Pukka Productions founders Duncan and Sarah approach me in 2019 their existing website was in desperate need of a refresh. We were able to work together to produce a modern Wordpress website that does their fantastic theatre shows justice.",
  },
  {
    id: "C071BF16-124F-4F68-B536-070E219E5AA3",
    title: "Clare Treverrow",
    subtitle: "Local Freelance Graphic Designer",
    href: "/work/clare-treverrow",
    externalHref: "https://claretrevs.com",
    imageSrc: "/images/portfolio/clare-treverrow-link.jpg",
    summary:
      "Working with Clare on her portfolio website presented a unique opportunity for a truely creative collaboration. When Clare approached me to develop her new website with designs in hand, she already knew exactly what she had in mind.",
  },
];
