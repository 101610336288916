import React from "react";

import Image from "next/image";
import { useWindowWidth } from "@react-hook/window-size";

import { Container, AnimateIn } from "@/components";
import { EMAIL_ADDRESS, TWITTER_LINK } from "@/constants";

import { StyledFooter } from "./styled";

const Footer = () => {
  const width = useWindowWidth();

  return (
    <StyledFooter>
      <AnimateIn>
        <Container>
          <div className="pt-12">
            <p className="font-bold text-lg">
              Luke Brown Web Design and Development
            </p>
            <p className="mt-2 text-lg italic">
              Creative freelance web designer and developer serving clients and
              agencies in and around Wales.
            </p>
          </div>
        </Container>

        <nav className="pt-8 pb-10 md:pt-4">
          <Container>
            <div className="mt-4 flex items-center justify-between md:mt-5">
              <Image
                src="/images/logo-small.svg"
                width={width >= 640 ? 35 : 28}
                height={width >= 640 ? 35 : 28}
                alt="Luke Brown Logo"
                priority
              />
              <ul className="flex">
                <li className="mr-6 md:mr-8">
                  <a
                    className="font-semiBold hover:text-primary "
                    href={`mailto:${EMAIL_ADDRESS}`}
                  >
                    hello@lukebrown.io
                  </a>
                </li>
                <li className="hidden sm:block mr-6 md:mr-8">
                  <a
                    className="font-semiBold hover:text-primary "
                    href={TWITTER_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    @lukejohnbrown
                  </a>
                </li>
              </ul>
            </div>
          </Container>
        </nav>
      </AnimateIn>
    </StyledFooter>
  );
};
export default Footer;
