import React from "react";

import { useMenu } from "@/context";
import { Container, Button, AnimateIn } from "@/components";

import {
  StyledBanner,
  StyledButtons,
  StyledTitle,
  StyledSubtitle,
  StyledBody,
} from "./styled";
import { BannerProps } from "./types";

const Banner: React.FC<BannerProps> = ({
  title,
  subtitle,
  body,
  buttonOne,
  buttonTwo,
  isSnug = false,
}) => {
  const { theme } = useMenu();
  return (
    <StyledBanner menuTheme={theme} isSnug={isSnug}>
      <AnimateIn>
        <Container>
          <StyledTitle menuTheme={theme}>{title}</StyledTitle>
          {subtitle && (
            <StyledSubtitle menuTheme={theme}>{subtitle}</StyledSubtitle>
          )}
          {body && <StyledBody menuTheme={theme}>{body}</StyledBody>}
          {(buttonOne || buttonTwo) && (
            <StyledButtons>
              <Button {...buttonOne} />
              <Button {...buttonTwo} />
            </StyledButtons>
          )}
        </Container>
      </AnimateIn>
    </StyledBanner>
  );
};

export default Banner;
