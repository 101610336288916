import React from "react";
import Link from "next/link";

import { useMenu, MenuTheme } from "@/context";

const Logo = () => {
  const { theme } = useMenu();

  return (
    <Link href="/">
      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="transform lg:scale-110 m-auto"
      >
        <ellipse
          cx="26"
          cy="25.9019"
          rx="26"
          ry="25.9019"
          className="fill-primary"
          fill={theme === MenuTheme.Pink ? "white" : "#CC3363"}
        />
        <path
          d="M27.1211 31.2047C27.1211 28.8372 29.0404 26.9178 31.408 26.9178H34.4977C35.1589 26.9178 35.6949 27.4538 35.6949 28.115C35.6949 28.7762 35.1589 29.3122 34.4977 29.3122H31.3207C30.8523 29.3122 30.4727 29.6919 30.4727 30.1602C30.4727 30.6285 30.8523 31.0082 31.3207 31.0082H33.7183C34.3794 31.0082 34.9154 31.5442 34.9154 32.2054C34.9154 32.8666 34.3794 33.4026 33.7183 33.4026H31.4371C30.9044 33.4026 30.4727 33.8343 30.4727 34.3669C30.4727 34.8996 30.9044 35.3313 31.4371 35.3313H34.6925C35.3537 35.3313 35.8897 35.8673 35.8897 36.5285C35.8897 37.1897 35.3537 37.7257 34.6925 37.7257H31.5054C29.084 37.7257 27.1211 35.7628 27.1211 33.3414V31.2047Z"
          fill={theme === MenuTheme.Pink ? "#CC3363" : "white"}
        />
        <path
          d="M15.9062 28.6102C15.9062 27.6755 16.6639 26.9178 17.5986 26.9178C18.5332 26.9178 19.2909 27.6755 19.2909 28.6102V30.8891C19.2909 30.9182 19.3144 30.9417 19.3435 30.9417C19.3597 30.9417 19.375 30.9342 19.3849 30.9214L22.081 27.4557C22.3451 27.1163 22.7509 26.9178 23.1809 26.9178C24.3692 26.9178 25.0122 28.3097 24.242 29.2146L23.4079 30.1944C22.8442 30.8566 22.7763 31.8083 23.2404 32.5438L25.0204 35.3647C25.6672 36.3898 24.9306 37.7257 23.7185 37.7257C23.1593 37.7257 22.6441 37.4225 22.3726 36.9337L21.2442 34.9019C20.8881 34.2607 19.9988 34.1781 19.5307 34.7427C19.3757 34.9297 19.2909 35.165 19.2909 35.4079V36.0334C19.2909 36.968 18.5332 37.7257 17.5986 37.7257C16.6639 37.7257 15.9062 36.968 15.9062 36.0334V28.6102Z"
          fill={theme === MenuTheme.Pink ? "#CC3363" : "white"}
        />
        <path
          d="M27.1211 15.4246C27.1211 14.5641 27.8187 13.8666 28.6792 13.8666C29.5396 13.8666 30.2372 14.5641 30.2372 15.4246V20.5621C30.2372 21.0106 30.3278 21.379 30.509 21.6673C30.7022 21.9557 31.0464 22.0998 31.5416 22.0998C32.0489 22.0998 32.3992 21.9557 32.5924 21.6673C32.7857 21.379 32.8823 21.0106 32.8823 20.5621V15.3703C32.8823 14.5398 33.5555 13.8666 34.386 13.8666C35.2165 13.8666 35.8897 14.5398 35.8897 15.3703V20.2417C35.8897 20.8931 35.8052 21.4805 35.6361 22.0037C35.4791 22.5163 35.2254 22.9595 34.8752 23.3332C34.5249 23.6963 34.072 23.9793 33.5164 24.1822C32.9729 24.3744 32.3146 24.4705 31.5416 24.4705C30.7687 24.4705 30.1044 24.3744 29.5488 24.1822C28.9932 23.9793 28.5342 23.6963 28.1719 23.3332C27.8095 22.9595 27.5438 22.5163 27.3747 22.0037C27.2056 21.4805 27.1211 20.8931 27.1211 20.2417V15.4246Z"
          fill={theme === MenuTheme.Pink ? "#CC3363" : "white"}
        />
        <path
          d="M15.9062 15.5461C15.9062 14.6185 16.6582 13.8666 17.5858 13.8666C18.5133 13.8666 19.2653 14.6185 19.2653 15.5461V19.4165C19.2653 20.9103 20.4763 22.1213 21.9701 22.1213H23.5003C24.149 22.1213 24.6749 22.6472 24.6749 23.2959C24.6749 23.9446 24.149 24.4705 23.5003 24.4705H20.2906C17.8692 24.4705 15.9062 22.5076 15.9062 20.0862V15.5461Z"
          fill={theme === MenuTheme.Pink ? "#CC3363" : "white"}
        />
      </svg>
    </Link>
  );
};

export default Logo;
