import React, { useEffect } from "react";
import { MDXProvider } from "@mdx-js/react";
import Head from "next/head";

import {
  Container,
  AnimateIn,
  Code,
  Title,
  Quote,
  NavigationSpacer,
  Button,
} from "@/components";
import { useMenu, MenuTheme } from "@/context";
import { PostMeta } from "@/types";

import {
  StyledWrapper,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledP,
  StyledA,
  StyledUl,
  StyledOl,
} from "./styled";

const mdComponents = {
  h2: ({ children }: { children: React.ReactNode }) => (
    <StyledH2>{children}</StyledH2>
  ),
  h3: ({ children }: { children: React.ReactNode }) => (
    <StyledH3>{children}</StyledH3>
  ),
  h4: ({ children }: { children: React.ReactNode }) => (
    <StyledH4>{children}</StyledH4>
  ),
  p: ({ children }: { children: React.ReactNode }) => (
    <StyledP>{children}</StyledP>
  ),
  a: ({ children, ...rest }: { children: React.ReactNode }) => (
    <StyledA {...rest}>{children}</StyledA>
  ),
  ul: ({ children }: { children: React.ReactNode }) => (
    <StyledUl>{children}</StyledUl>
  ),
  ol: ({ children }: { children: React.ReactNode }) => (
    <StyledOl>{children}</StyledOl>
  ),
  code: ({ children, ...rest }: { children: React.ReactNode }) => (
    <Code {...rest}>{children}</Code>
  ),
  blockquote: ({ children, ...rest }: { children: React.ReactNode }) => (
    <Quote {...rest}>{children}</Quote>
  ),
};

const Post: React.FC<{ meta: PostMeta }> = ({ children, meta }) => {
  const { setTheme } = useMenu();
  useEffect(() => {
    setTheme(MenuTheme.White);
  }, []);

  return (
    <>
      <Head>
        <title>{meta.title} | Luke Brown</title>
        <meta name="description" content={meta.subtitle} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.subtitle} />
        <meta
          property="og:image"
          content={`https://og.lukebrown.io/${encodeURIComponent(meta.title)}`}
        />
      </Head>
      <NavigationSpacer />
      <Container>
        <AnimateIn>
          <div className="mt-4 md:mt-8 lg:mt-12">
            <h1 className="font-extraBold text-3xl leading-tight xs:text-4xl lg:text-4.5xl lg:leading-tight">
              {meta.title}
            </h1>
          </div>

          <StyledWrapper>
            <MDXProvider components={mdComponents}>{children}</MDXProvider>
            <div className="mt-10 md:mt-12">
              <Button text="Read more posts" href="/writing" />
            </div>
          </StyledWrapper>
        </AnimateIn>
      </Container>
    </>
  );
};

export default Post;
