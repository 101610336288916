import React, { createContext, useContext, useState, useEffect } from "react";
import { useRouter } from "next/router";
import useScrollPosition from "@react-hook/window-scroll";

export enum MenuTheme {
  Pink = "Pink",
  Grey = "Grey",
  White = "White",
  Image = "Image",
}

const MenuContext = createContext<{
  openMenu: () => void;
  closeMenu: () => void;
  isOpen: boolean;
  setTheme: (theme: MenuTheme) => void;
  theme: MenuTheme;
}>({
  openMenu: () => {},
  closeMenu: () => {},
  setTheme: () => {},
  isOpen: false,
  theme: MenuTheme.Pink,
});

export const useMenu = () => useContext(MenuContext);
export const MenuConsumer = MenuContext.Consumer;

export const MenuProvider: React.FC = ({ children }) => {
  const scrollY = useScrollPosition(60);
  const [isOpen, setIsOpen] = useState(false);
  const [theme, setTheme] = useState(MenuTheme.Pink);
  const { events } = useRouter();

  useEffect(() => {
    if (scrollY > 150) {
      setIsOpen(false);
    }
  }, [scrollY]);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false);
    };

    events.on("routeChangeStart", handleRouteChange);
    return () => {
      events.off("routeChangeStart", handleRouteChange);
    };
  }, []);

  const openMenu = () => {
    setIsOpen(true);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <MenuContext.Provider
      value={{ isOpen, openMenu, closeMenu, theme, setTheme }}
    >
      {children}
    </MenuContext.Provider>
  );
};
