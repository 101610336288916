import React, { useState } from "react";

import ContactForm from "./ContactForm";

const DEFAULT_VALUES = {
  name: "",
  email: "",
  business: "",
  telephone: "",
  message: "",
};

const ContactFormWithState = () => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [values, setValues] = useState(DEFAULT_VALUES);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);
    setSubmitting(true);

    const res = await (
      await fetch("/api/mailer", {
        method: "POST",
        body: JSON.stringify(values),
      })
    ).json();

    setSubmitting(false);
    if (res.success) {
      setSuccess(true);
      setValues(DEFAULT_VALUES);
    } else {
      setError(true);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (success || error) {
      setSuccess(false);
      setError(false);
    }

    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <ContactForm
      values={values}
      onChange={handleChange}
      onSubmit={handleSubmit}
      submitting={submitting}
      success={success}
      error={error}
    />
  );
};

export default ContactFormWithState;
