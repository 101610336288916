import React from "react";

import { Container, Button, PostLink } from "@/components";
import { getAllPosts } from "@/util";
import { Post } from "@/types";

import { LatestPostsProps } from "./types";

const LatestPosts: React.FC<LatestPostsProps> = ({
  count,
  withTitle = true,
  withButton = true,
  isSnug = false
}) => (
  <div className={isSnug ? "py-12" : "py-12 md:py-16 lg:py-20"}>
    <Container>
      <div>
        {withTitle && (
          <h2 className="text-xl font-bold mb-10 md:mb-12 lg:text-2xl">
            Latest Blog Posts
          </h2>
        )}
      </div>
      {getAllPosts
        .slice(0, count || getAllPosts.length)
        .map((post: Post, idx: number) => (
          <article
            key={idx}
            className={idx + 1 < getAllPosts.length ? "mb-12 md:mb-16" : ""}
          >
            <PostLink {...post} />
          </article>
        ))}
      {withButton && <Button href="/writing" text="Read more blog posts" />}
    </Container>
  </div>
);

export default LatestPosts;
