import React from "react";

import { useMenu } from "@/context";

const Overlay = () => {
  const { isOpen, closeMenu } = useMenu();
  return (
    <div
      className={`transition-opacity z-10 ease-in-out duration-200 fixed h-full w-full bg-black ${
        isOpen ? "opacity-50" : "opacity-0 pointer-events-none"
      } left-0 sm:hidden`}
      onClick={() => closeMenu()}
    ></div>
  );
};

export default Overlay;
