import React from "react";
import Link from "next/link";

import { useMenu } from "@/context";

import { StyledMobileMenu, StyledGreeting } from "../styled";
import { getGreeting } from "../util";

const mobileMenuAnimations = {
  wrapper: {
    open: { height: "280px" },
    closed: { height: 0 },
  },
};

const MobileMenu = () => {
  const { theme, isOpen } = useMenu();
  return (
    <StyledMobileMenu
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      transition={{ ease: "easeInOut", duration: 0.2 }}
      variants={mobileMenuAnimations.wrapper}
      isMenuOpen={isOpen}
      menuTheme={theme}
    >
      <StyledGreeting isMenuOpen={isOpen} menuTheme={theme}>
        {getGreeting()}
      </StyledGreeting>
      <ul>
        <li>
          <Link href="/contact">Get in touch</Link>
        </li>
        <li>
          <Link href="/services">View services</Link>
        </li>
        <li>
          <Link href="/work">Latest Projects</Link>
        </li>
        <li>
          <a href="https://lukebrown.dev" target="_blank" rel="norefferer">Read Blog</a>
        </li>
      </ul>
    </StyledMobileMenu>
  );
};

export default MobileMenu;
