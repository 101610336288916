import React from "react";

import { TextField, Button } from "@/components";

type ContactFormProps = {
  onSubmit: (e: React.FormEvent) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: {
    name: string;
    email: string;
    business: string;
    telephone: string;
    message: string;
  };
  submitting?: boolean;
  success?: boolean;
  error?: boolean;
};

const ContactForm: React.FC<ContactFormProps> = ({
  onSubmit,
  onChange,
  values,
  submitting = false,
  error = false,
  success,
}) => (
  <form onSubmit={onSubmit}>
    <TextField
      onChange={onChange}
      value={values.name}
      label="Your name"
      name="name"
    />

    <TextField
      onChange={onChange}
      value={values.business}
      label="Your business"
      name="business"
      required={false}
      className="mt-8"
    />

    <TextField
      onChange={onChange}
      value={values.email}
      label="Your email address"
      name="email"
      type="email"
      className="mt-8"
    />

    <TextField
      onChange={onChange}
      value={values.telephone}
      label="Your telephone number"
      name="telephone"
      type="telephone"
      className="mt-8"
      required={false}
    />

    <TextField
      onChange={onChange}
      value={values.message}
      label="How can I help you?"
      name="message"
      multiline
      className="mt-8"
    />

    {success && (
      <p className="top-0 left-0 mt-4 font-semiBold text-green-700 leading-relaxed">
        Great! I have received your message. Thank you for getting in touch, I
        will get back to you as soon as possible.
      </p>
    )}

    {error && (
      <p className="top-0 left-0 mt-4 font-semiBold text-red-600 leading-relaxed">
        Whoops, it looks like something went wrong. Please try again, or feel
        free to drop me an email using the contact details above.
      </p>
    )}

    <div className="mt-8">
      <Button text={submitting ? "Sending message..." : "Send message"} />
    </div>
  </form>
);

export default ContactForm;
