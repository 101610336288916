import styled from "styled-components";

import { theme } from "@/theme";

export const StyledSpacer = styled.div`
  height: 100px;
  @media (min-width: ${theme.screens.sm}) {
    height: 116px;
  }
  @media (min-width: ${theme.screens.md}) {
    height: 116px;
  }
`;
