import React from "react";

const Notice: React.FC<{
  title?: string;
}> = ({ title, children }) => (
  <div className="px-5 py-4 mt-6 bg-grey rounded-md leading-extra-relaxed">
    {title && <h3 className="italic font-semiBold">{title}</h3>}
    {children && <p className="mt-2 italic">{children}</p>}
  </div>
);

export default Notice;
