import styled from "styled-components";

import { theme } from "@/theme";

export const StyledBanner = styled.div<{
  mobileBannerSrc: string;
  desktopBannerSrc: string;
}>`
  background-image: url(${({ mobileBannerSrc }) => mobileBannerSrc});
  background-position: center;
  background-size: cover;
  @media (min-width: ${theme.screens.md}) {
    background-image: url(${({ desktopBannerSrc }) => desktopBannerSrc});
  }
`;
