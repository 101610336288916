import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { theme } from "@/theme";

const StyledContainer = styled.div`
  ${tw`px-5 w-full`}

  @media (min-width: ${theme.screens.sm}) {
    max-width: calc(${theme.screens.sm} - 80px);
    margin: 0 auto;
    padding: 0;
  }

  @media (min-width: ${theme.screens.md}) {
    max-width: calc(${theme.screens.md} - 80px);
  }

  @media (min-width: ${theme.screens.xl}) {
    transform: translateX(-5vw);
  }
`;

const Container: React.FC = ({ children }) => (
  <StyledContainer>{children}</StyledContainer>
);

export default Container;
