import React, { useEffect, useState } from "react";

import { PROJECTS } from "@/constants";

import PortfolioLink from "./PortfolioLink";

const PortfolioLinkWithState = () => {
  const [selectedProject, setSelectedProject] = useState<any>();

  useEffect(() => {
    if (!selectedProject) return;

    if (typeof window !== "undefined" && selectedProject) {
      // @ts-ignore
      window.localStorage.setItem("lastProject", selectedProject.id);
    }
  }, [selectedProject]);

  useEffect(() => {
    const lastProject =
      typeof window !== "undefined"
        ? window.localStorage.getItem("lastProject")
        : null;

    const filteredProjects = PROJECTS.filter(
      (project) => project.id !== lastProject
    );

    const randomProject =
      filteredProjects[Math.floor(Math.random() * filteredProjects.length)];

    setSelectedProject(randomProject);
  }, []);

  return <>{selectedProject && <PortfolioLink {...selectedProject} />}</>;
};

export default PortfolioLinkWithState;
