import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";

import { MenuTheme } from "@/context";
import { theme } from "@/theme";

import { getLinkColor, getLinkHoverColor } from "./util";

export const StyledNav = styled.nav<{
  isMenuOpen: boolean;
  menuTheme: MenuTheme;
}>`
  ${tw`flex items-center`}
  li {
    &:not(:last-child) {
      ${tw`mb-3`}
      @media (min-width: ${theme.screens.sm}) {
        ${tw`sm:pr-6 md:pr-10 xl:pr-12`}
      }
    }

    a,
    button {
      ${tw`font-semiBold`}
      outline: none;
      color: ${({ isMenuOpen, menuTheme }) =>
        getLinkColor(isMenuOpen, menuTheme)};
      &:hover {
        color: ${({ menuTheme }) => getLinkHoverColor(menuTheme)};
      }
    }
  }
`;

export const StyledWrapper = styled.div<{
  isMenuOpen: boolean;
  menuTheme: MenuTheme;
}>`
  ${tw`px-5 absolute w-full z-20 leading-4 flex justify-between items-center font-bold py-6 sm:pt-10 xl:pr-40`}
  ${({ menuTheme, isMenuOpen }) =>
    menuTheme === MenuTheme.Pink
      ? tw`bg-primary`
      : isMenuOpen
      ? tw`bg-white`
      : tw`bg-transparent`};

  @media (min-width: ${theme.screens.sm}) {
    padding-left: calc((100vw - ${theme.screens.sm}) / 2 + 40px);
  }

  @media (min-width: ${theme.screens.md}) {
    padding-left: calc((100vw - ${theme.screens.md}) / 2 + 40px);
  }
`;

export const StyledGreeting = styled.p<{
  isMenuOpen: boolean;
  menuTheme: MenuTheme;
}>`
  ${tw`text-lg leading-relaxed font-extraBold`}
  color: ${({ isMenuOpen, menuTheme }) => getLinkColor(isMenuOpen, menuTheme)};
`;

export const StyledMobileMenu = styled(motion.nav)<{
  isMenuOpen: boolean;
  menuTheme: MenuTheme;
}>`
  top: 100%;
  ${tw`sm:hidden px-5 absolute w-full z-10 left-0 overflow-hidden flex flex-col justify-center`}
  ${({ menuTheme }) =>
    menuTheme === MenuTheme.Pink ? tw`bg-primary` : tw`bg-white`};

  ul {
    ${tw`mt-8`}
    li {
      ${tw`pb-8`}

      a,
      button {
        ${tw`font-semiBold`}
        color: ${({ isMenuOpen, menuTheme }) =>
          getLinkColor(isMenuOpen, menuTheme)};
        &:hover {
          color: ${({ menuTheme }) => getLinkHoverColor(menuTheme)};
        }
      }
    }
  }
`;

export const StyledLogo = styled.div`
  /* shift logo left in line with container  */
  @media (min-width: ${theme.screens.xl}) {
    transform: translateX(-5vw);
  }
`;
