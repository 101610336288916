import { MenuTheme } from "@/context";
import { theme } from "@/theme";

export const getLinkColor = (isMenuOpen: boolean, menuTheme: MenuTheme) => {
  if (menuTheme === MenuTheme.Pink) {
    return isMenuOpen ? "white" : "#EBADC1";
  }

  if (menuTheme === MenuTheme.Image && !isMenuOpen) {
    return "white";
  }

  return theme.extend.textColor.primary;
};

export const getLinkHoverColor = (menuTheme: MenuTheme) => {
  if (menuTheme === MenuTheme.Pink) {
    return "white";
  }

  return theme.extend.colors.primary;
};

export const getGreeting = () => {
  const today = new Date();
  const hours = today.getHours();

  if (hours < 12) {
    return "What can I help you with this morning?";
  } else if (hours < 18) {
    return "What can I help you with this afternoon?";
  } else {
    return "What can I help you with this evening?";
  }
};
